import React from 'react';

import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import MCKArena2Full from '../../components/Schedule/Whitby/MCKArena2Full';


export default function MCKArena2() {
    return (
        <>
            <HeaderIPSCLogo />
            <MCKArena2Full />
        </>
    )
}