import React from 'react';
import HeaderWestEnd from '../../components/Header/Guelph/HeaderWestEnd';
import JumbotronWestEnd from '../../components/Jumbotron/Guelph/JumbotronWestEnd';
import WestEndFull2 from '../../components/Schedule/Guelph/WestEndFull2';

export default function WestEndFull2Template () {
    return (
        <>
        <HeaderWestEnd />
        <WestEndFull2 />
        <JumbotronWestEnd />
        </>
    )
}