import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import './Jumbotron.css'

export default function JumbotronEditHarrow() {
    const { jumbotronHarrow, addJumbotronHarrow, deleteJumbotronHarrow } = useContext(ScheduleContext);
    const [jumbotronList, setJumbotronList] = useState([]);
    const [editIndex] = useState(-1);
    const [newJumbotron, setNewJumbotron] = useState({ description: '' });
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setJumbotronList(jumbotronHarrow);
    }, [jumbotronHarrow]);

    const handleChange = (index, field, value) => {
        const updatedJumbotron = jumbotronList.map((item, idx) =>
            idx === index ? { ...item, [field]: value } : item
        );
        setJumbotronList(updatedJumbotron);
    };

    const handleAddJumbotron = () => {
        addJumbotronHarrow(newJumbotron.description);
        setNewJumbotron({ description: '' });
    };

    const handleNewJumbotronChange = (field, value) => {
        setNewJumbotron(prev => ({ ...prev, [field]: value }));
    };

    const handleDelete = (id) => {
        deleteJumbotronHarrow(id);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className='container-fluid'>
            <h1 className='kanit-regular'>Harrow Message Ticker</h1>
            <div className="col mb-4">
                <div className="card h-100">
                    <div className="h2 bg-warning btn btn-secondary btn-lg card-header text-light" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
                        Message Ticker
                    </div>
                    <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '75%' }}>Enter Text</th>
                                            <th style={{ width: '25%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jumbotronList.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={editIndex === index ? jumbotronList[index].description : item.description}
                                                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                                                        disabled={editIndex !== index}
                                                    />
                                                </td>
                                                <td>
                                                    <button className='btn btn-danger m-1' onClick={() => handleDelete(item.id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={newJumbotron.description}
                                                    onChange={(e) => handleNewJumbotronChange('description', e.target.value)}
                                                    placeholder="New Message Ticker Text"
                                                    style={{ width: '66%' }}
                                                />
                                            </td>
                                            <td>
                                                <button className='btn btn-success m-3' onClick={handleAddJumbotron}>Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
