import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import ContactUs from '../components/Contact/ContactUs';

function LandingPage() {
  const slideInFromLeft = {
    initial: { opacity: 0, x: -100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration: 0.5 }
  };

  const slideInFromRight = {
    initial: { opacity: 0, x: 100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration: 0.5 }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">Arena Scheduling</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#overview">Overview</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#templates">Templates</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <header className="text-center py-5">
        <div className="container d-flex align-items-center justify-content-between">
          <motion.div className="text-left" {...slideInFromLeft}>
            <h1 className="display-3">Plan and manage schedules effortlessly</h1>
          </motion.div>
          <motion.div className="image-container" {...slideInFromRight}>
            <img src="/hockey-arena-crop.png" className="img-fluid rounded" alt="Scheduler Preview" />
          </motion.div>
        </div>
      </header>

      <section className="py-5 bg-light" id="about-us">
        <div className="container">
          <motion.h2 className="text-center mb-5" {...slideInFromLeft}>About Us</motion.h2>
          <p className="lead text-center mb-4">
            At Harris Time, we take pride in our 40-year history of excellence in the scoreboard industry. We design and deliver high-quality, durable equipment that ensures years of trouble-free operation. Our reputation as a top scoreboard solutions provider is built on this commitment.
          </p>
          <p className="lead text-center mb-4">
            Harris Time is your trusted partner for the best products and services to enhance your facility's capabilities. Let us provide you with expert solutions and unparalleled support.
          </p>
        </div>
      </section>

      <section className="py-5" id="overview">
        <div className="container">
          <motion.h2 className="text-center mb-5" {...slideInFromLeft}>Overview</motion.h2>
          <div className="row mb-4">
            <div className="col-md-12">
              <motion.ul className="list-group mb-4" {...slideInFromRight}>
                <li className="list-group-item py-3">
                  <h4><span className="material-symbols-outlined">calendar_month</span> Schedule information is loaded automatically for the upcoming month.</h4>
                </li>
                <li className="list-group-item py-3">
                  <h4><span className="material-symbols-outlined">database</span> Data is stored in databases to maintain integrity and reliability.</h4>
                </li>
                <li className="list-group-item py-3">
                  <h4><span className="material-symbols-outlined">calendar_add_on</span> Scheduling information can be created and modified from the easy-to-use platform.</h4>
                </li>
                <li className="list-group-item py-3">
                  <h4><span className="material-symbols-outlined">dashboard_customize</span> Add custom features for your specific business needs.</h4>
                </li>
              </motion.ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <motion.div {...slideInFromLeft}>
                <img src="/arena-edit.png" className="img-fluid rounded border" alt="Edit Page" />
              </motion.div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-8">
              <motion.div className="card mb-3" {...slideInFromLeft}>
                <div className="card-body">
                  <h4 className="card-title">Easy Scheduling</h4>
                  <p className="card-text fs-5">Effortlessly plan and manage your events with our intuitive scheduling tools.</p>
                </div>
              </motion.div>
              <motion.div className="card mb-3" {...slideInFromLeft}>
                <div className="card-body">
                  <h4 className="card-title">Recurring Events</h4>
                  <p className="card-text fs-5">Create recurring events with a few simple clicks.</p>
                </div>
              </motion.div>
              <motion.div className="card mb-3" {...slideInFromLeft}>
                <div className="card-body">
                  <h4 className="card-title">Import and Export Schedules</h4>
                  <p className="card-text fs-5">Import and export schedule data to streamline your workflow.</p>
                </div>
              </motion.div>
              <motion.div className="card mb-3" {...slideInFromLeft}>
                <div className="card-body">
                  <h4 className="card-title">Manage Team Names</h4>
                  <p className="card-text fs-5">Provide team name mapping for autofill features.</p>
                </div>
              </motion.div>
            </div>
            <div className="col-md-4">
              <motion.div {...slideInFromRight} className='card'>
                <div className='h4 card-header bg-primary text-white'>
                  Recurring Event Modal
                </div>
                <div className='card-body'>
                  <img src="/recurring-event-modal.png" className="img-fluid rounded img-thumbnail border" alt="Recurring Event" style={{'maxWidth': 'auto'}}/>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light" id="templates">
        <div className="container">
          <motion.h2 className="text-center mb-4" {...slideInFromLeft}>Templates</motion.h2>
          <p className="lead text-center mb-5">View the schedule information from a variety of templates to choose from. Custom templates can be made on demand.</p>
          <div id="carouselTemplates" className="carousel slide carousel-fade" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="/essex1.png" className="d-block w-100 rounded border" alt="Essex Schedule" />
                <div className='text-center m-2'>
                  <Link to="/essex" className="btn btn-primary btn-lg mt-3">View</Link>
                </div>
              </div>
              {/* <div className="carousel-item">
                <img src="/essex2.png" className="d-block w-100 rounded border" alt="Full Schedule" />
                <div className='text-center m-2'>
                  <Link to="/essex-full" className="btn btn-primary btn-lg">View</Link>
                </div>
              </div> */}
              <div className="carousel-item">
                <img src="/essex3.png" className="d-block w-100 rounded border" alt="Schedule with Ads" />
                <div className='text-center m-2'>
                  <Link to="/essex-ads" className="btn btn-primary btn-lg mt-3">View</Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src="/harrow1.png" className="d-block w-100 rounded border" alt="Harrow Schedule" />
                <div className='text-center m-2'>
                  <Link to="/harrow" className="btn btn-primary btn-lg mt-3">View</Link>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselTemplates" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselTemplates" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      <ContactUs />

      <footer className="bg-dark text-white text-center py-4">
        <div className="container">
          <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
