import React, { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import Teams from '../../components/Teams/WestHants/Teams';
import HeaderWestHantsEdit from '../../components/Header/WestHants/HeaderWestHantsEdit';
import LoginWestHants from '../../components/Auth/WestHants/LoginWestHants';
import ScheduleEdit from '../../components/Schedule/WestHants/ScheduleEdit';


export default function WestHantsEdit() {
const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
            <>
            <HeaderWestHantsEdit />
            <br></br>
            <ScheduleEdit />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
            </>
            ) : (
                <LoginWestHants />
            )}
        </>
    )
}