import React from 'react';
import Header from '../components/Header/Header';
import ScheduleList from '../components/Schedule/ScheduleList';
import RoomListAds from '../components/Room/RoomListAds';

export default function EssexScheduleAds() {
    return(
        <>
            <Header />
            <ScheduleList />
            <RoomListAds />
        </>
    );
}