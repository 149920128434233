import React, { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import HeaderWellingtonEdit from '../../components/Header/Wellington/HeaderWellingtonEdit';
import Teams from '../../components/Teams/Wellington/Teams';
import LoginWellington from '../../components/Auth/Wellington/LoginWellington'
import ScheduleEditWellington from '../../components/Schedule/Wellington/ScheduleEditWellington';
import ManageAndUploadAds from '../../components/Ads/UploadAds';


export default function WellingtonEdit() {
const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
            <>
            <HeaderWellingtonEdit />
            <br></br>
            <ScheduleEditWellington />
            <br></br>
            <ManageAndUploadAds />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
            </>
            ) : (
                <LoginWellington />
            )}
        </>
    )
}