import React from 'react';
import HeaderVictoria from '../../components/Header/Guelph/HeaderVictoria';
import VictoriaFull from '../../components/Schedule/Guelph/VictoriaFull';
import JumbotronVictoria from '../../components/Jumbotron/Guelph/JumbotronVictoria';

export default function VictoriaFullTemplate () {
    return (
        <>
        <HeaderVictoria />
        <VictoriaFull />
        <JumbotronVictoria />
        </>
    )
}