import React from 'react';
import HeaderExhibition from '../../components/Header/Guelph/HeaderExhibition';
import ExhibitionFull from '../../components/Schedule/Guelph/ExhibitionFull';
import JumbotronExhibition from '../../components/Jumbotron/Guelph/JumbotronExhibition';

export default function ExhibitionFullTemplate () {
    return (
        <>
        <HeaderExhibition />
        <ExhibitionFull />
        <JumbotronExhibition />
        </>
    )
}