import React, { useState, useEffect } from 'react';
import '../Header.css'; // Make sure to import your CSS file

export default function HeaderVictoria() {
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return (
        <nav className="navbar navbar-expand-sm py-3" style={{ backgroundColor: 'rgb(0, 167, 224)' }}>
            <div className="container-fluid d-flex justify-content-between align-items-center">
                {/* Left Logo */}
                <div className="navbar-brand">
                    <img src="/RecEnroll_Logo.png" alt="RecEnroll Logo" width="270" height="90" className="d-inline-block align-text-top rounded img-thumbnail m-2" />
                </div>

                {/* Center Content - Title and Date/Time */}
                <div className="text-center">
                    <span className="navbar-text display-3 display-md-2 text-white kanit-regular">
                        Victoria Road Recreation Centre
                    </span>
                    <div className="d-flex justify-content-center">
                        <span className="navbar-text h1 kanit-regular me-3" style={{ color: '#FFFFFF' }}>
                            {formattedDate} | {formattedTime}
                        </span>
                    </div>
                </div>

                {/* Right Logo with Logout Button */}
                <div className="navbar-brand d-flex flex-column align-items-end">
                    <img src="/c_of_g.jpg" alt="City of Guelph Logo" width="270" height="90" className="d-inline-block align-text-top rounded img-thumbnail m-2" />
                </div>
            </div>
        </nav>
    );
}
