import React from 'react';
import ScheduleListElora from '../../components/Schedule/Wellington/ScheduleListElora';
import RoomListElora from '../../components/Room/Wellington/RoomListElora';
import HeaderElora from '../../components/Header/Wellington/HeaderElora';

export default function EloraList() {
    return (
        <>
            <HeaderElora />
            <ScheduleListElora />
            <RoomListElora />
        </>
    )
}