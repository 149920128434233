import React, { useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import DateSelector from '../../DatePicker/DateSelector';
import ExportCSV from '../../FileUpload/ExportCSV';
import ImportCSV from '../../FileUpload/ImportCSV';
import '../Schedule.css';
import RoomEdit from '../../Room/RoomEdit';
import VenueScheduleEditWellington from './VenueScheduleEditWellington';
import RoomEditWellington from '../../Room/Wellington/RoomEdit';

export default function ScheduleEditWellington() {
  const { schedulesWellington, selectedDateWellington, setSelectedDateWellington, saveSchedules } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedulesWellington.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueName) => schedulesWellington.filter(s => s.Venue === venueName && s.Date === selectedDateWellington);

  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedules(venue, venueSchedules).catch(error => {
        console.error(`Error saving schedules for ${venue}:`, error);
        alert(`Error saving schedules for ${venue}. Please check the console for more details.`);
      });
    });
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDateWellington} onDateChange={setSelectedDateWellington} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          <ExportCSV data={schedulesWellington} venues={uniqueVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>
      <div className="row mt-3">
        <h1 className='kanit-regular'>Wellington Pad Booking</h1>
        <VenueScheduleEditWellington venueName="Pad A" schedules={filteredSchedules('Pad A')} />
        <VenueScheduleEditWellington venueName="Pad B" schedules={filteredSchedules('Pad B')} />
        <h1 className='kanit-regular'>Wellington Room Booking</h1>
        <RoomEditWellington venueName="Full Hall" schedules={filteredSchedules('Full Hall')} />
        <RoomEditWellington venueName="Grod Brown Hall" schedules={filteredSchedules('Grod Brown Hall')} />
        <RoomEditWellington venueName="Unity Hall" schedules={filteredSchedules('Unity Hall')} />
        <RoomEditWellington venueName="Boardroom A" schedules={filteredSchedules('Boardroom A')} />
        <RoomEditWellington venueName="Boardroom B" schedules={filteredSchedules('Boardroom B')} />
        <RoomEditWellington venueName="Mezzanine" schedules={filteredSchedules('Mezzanine')} />
        <br></br>
        <br></br>
        <h1 className='kanit-regular'>Elora Arena</h1>
        <VenueScheduleEditWellington venueName="Elora Pad" schedules={filteredSchedules('Elora Pad')} />
        <h1 className='kanit-regular'>Elora Room Booking</h1>
        <RoomEditWellington venueName="Banquet Hall" schedules={filteredSchedules('Banquet Hall')} />
      </div>
    </div>
  );
}
