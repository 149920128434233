import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArenaFull from '../../components/Schedule/Whitby/IPSCArenaFull';
import Jumbotron from '../../components/Jumbotron/Whitby/Jumbotron';


export default function IPSCArenaRooms() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArenaFull />
            <Jumbotron />
        </>
    )
}