import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import RoomSchedule from './Room';
import Ads from '../Ads/Ads';

export default function RoomListAds() {
  const { schedules } = useContext(ScheduleContext);
  const [barnettSchedules, setBarnettSchedules] = useState([]);
  const [shaheenSchedules, setShaheenSchedules] = useState([]);

  useEffect(() => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];

    const filterSchedules = (venueName) => {
      return schedules.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const [hours, minutes] = s.EndTime.split(':').map(Number);
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    let barnett = ensureNEntries(filterSchedules('Barnett Board Room'));
    let shaheen = ensureNEntries(filterSchedules('Shaheen Community Room'));

    setBarnettSchedules(barnett);
    setShaheenSchedules(shaheen);
  }, [schedules]);

  function ensureNEntries(schedules) {
    const now = new Date();
  
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Filter out past events (events that ended before now)
    const upcomingSchedules = schedules.filter(s => {
      const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
      return endTime >= now;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = upcomingSchedules.slice(0, 1);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 1) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-2 text-center">
      <div className="row">
        <div className="col d-flex flex-column">
          <div className="flex-fill">
            <RoomSchedule venueName="Barnett Board Room" schedules={barnettSchedules} />
          </div>
          <div className="flex-fill mt-2">
            <RoomSchedule venueName="Shaheen Community Room" schedules={shaheenSchedules} />
          </div>
        </div>
        <div className="col">
          <Ads />
        </div>
      </div>
    </div>
  );
}
