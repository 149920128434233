import React from 'react';
import ScheduleListHarrow from '../components/Schedule/ScheduleListHarrow';
import RoomListHarrow from '../components/Room/RoomListHarrow';
import HeaderHarrow from '../components/Header/HeaderHarrow';
import JumbotronHarrow from '../components/Jumbotron/JumbotronHarrow';

export default function HarrowSchedule() {
    return(
        <>
            <HeaderHarrow />
            <ScheduleListHarrow />
            <RoomListHarrow />
            <JumbotronHarrow />
        </>
    );
}