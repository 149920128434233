import React, { useState } from 'react';

export default function RecurringEventModal ({show, handleClose, handleSubmit, venueName}) {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [days, setDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [team, setTeam] = useState('');
    const [opponent, setOpponent] = useState('');

    const handleDayChange = (day) => {
        setDays(prevDays => ({ ...prevDays, [day]: !prevDays[day] }));
    };

    const onFormSubmit = () => {
        // Collect all data and pass it to the handleSubmit function passed from parent
        handleSubmit({
        venue: venueName,
        times: { start: startTime, end: endTime },
        days,
        startDate,
        endDate,
        team,
        opponent
        });
        handleClose(); // Close modal after submit
    };

    if (!show) {
        return null;
    }

    return (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">{venueName}</h4>
            </div>
            <div className="modal-body">
                <form>
                <div className="form-group">
                    <label>Home Team</label>
                    <input type="text" className="form-control mb-1" value={team} onChange={(e) => setTeam(e.target.value)} placeholder="Team Name" />
                    <label>Away Team</label>
                    <input type="text" className="form-control" value={opponent} onChange={(e) => setOpponent(e.target.value)} placeholder="Opponent Name" />
                </div>
                <br />
                <div className="form-group">
                    <label>Event Time</label>
                    <input
                    type="time"
                    className="form-control mt-1"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    />
                    <input
                    type="time"
                    className="form-control mt-1"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    />
                </div>
                <br />
                <div className="form-group">
                    <label>Days</label>
                    {Object.keys(days).map((day) => (
                    <div key={day} className="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        checked={days[day]}
                        onChange={() => handleDayChange(day)}
                        id={day}
                        />
                        <label className="form-check-label" htmlFor={day}>
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                        </label>
                    </div>
                    ))}
                </div>
                <br />
                <div className="form-group">
                    <label>Start Date</label>
                    <input
                    type="date"
                    className="form-control mb-1"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    />
                    <label>End Date</label>
                    <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={onFormSubmit}>Submit</button>
            </div>
            </div>
        </div>
        </div>
    );
}