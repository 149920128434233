import React, { useState, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const ManageAndUploadAds = () => {
    const { adUrls, uploadAd, deleteAd } = useContext(ScheduleContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isUploadCollapsed, setIsUploadCollapsed] = useState(true);
    const [isManageCollapsed, setIsManageCollapsed] = useState(true);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            try {
                await uploadAd(selectedFile);
                alert("Ad uploaded successfully. Please refresh to view changes.");
                setSelectedFile(null);
                setPreviewUrl(null);
            } catch (error) {
                alert("Failed to upload ad.");
            }
        }
    };

    const handleDelete = async (url) => {
        const fileName = url.split('/').pop();
        try {
            await deleteAd(fileName);
            alert("Ad deleted successfully. Please refresh to view changes.");
        } catch (error) {
            alert("Failed to delete ad.");
        }
    };

    const toggleUploadCollapse = () => setIsUploadCollapsed(!isUploadCollapsed);
    const toggleManageCollapse = () => setIsManageCollapsed(!isManageCollapsed);

    return (
        <div className="container-fluid">
            <h1 className='kanit-regular'>Upload and Manage Ads</h1>
            <div className="row mb-4">
                {/* Upload Ad Container */}
                <div className="col-md-6 mb-4">
                    <div className="card h-100">
                        <div className="h2 btn btn-primary btn-lg card-header text-light" onClick={toggleUploadCollapse} style={{ cursor: 'pointer' }}>
                            Upload Ad
                        </div>
                        <div className={`collapse ${isUploadCollapsed ? '' : 'show'}`}>
                            <div className="card-body">
                                <div className="mb-3">
                                    {previewUrl && (
                                        <div className="mb-3">
                                            <img 
                                                src={previewUrl} 
                                                alt="Ad Preview" 
                                                className="img-fluid rounded" 
                                                style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                            />
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            onChange={handleFileChange} 
                                        />
                                        <button 
                                            className="btn btn-outline-info" 
                                            onClick={handleFileUpload} 
                                            disabled={!selectedFile}
                                        >
                                            Upload Ad
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Manage Ads Container */}
                <div className="col-md-6 mb-4">
                    <div className="card h-100">
                        <div className="h2 btn btn-primary btn-lg card-header text-light" onClick={toggleManageCollapse} style={{ cursor: 'pointer' }}>
                            Manage Ads
                        </div>
                        <div className={`collapse ${isManageCollapsed ? '' : 'show'}`}>
                            <div className="card-body">
                                <div className="row">
                                    {adUrls.length > 0 ? (
                                        adUrls.map((url, index) => (
                                            <div key={index} className="col-md-4 mb-3">
                                                <div className="d-flex flex-column align-items-center">
                                                    <img 
                                                        src={url} 
                                                        alt={`Ad ${index + 1}`} 
                                                        className="img-fluid rounded" 
                                                        style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                    />
                                                    <button 
                                                        className="btn btn-outline-danger mt-2" 
                                                        onClick={() => handleDelete(url)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No ads uploaded yet.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageAndUploadAds;
