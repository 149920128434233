import React, { useContext } from 'react';

import AuthContext from '../context/AuthContext';
import ScheduleEdit from '../components/Schedule/ScheduleEdit';
import Teams from '../components/Teams/Teams';
import Login from '../components/Auth/Login'
import JumbotronEdit from '../components/Jumbotron/JumbotronEdit';
import JumbotronEditHarrow from '../components/Jumbotron/JumbotronEditHarrow';
import ScheduleEditHarrow from '../components/Schedule/ScheduleEditHarrow';
import HeaderEdit from '../components/Header/HeaderEdit';


export default function EssexEdit() {
const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
            <>
            <HeaderEdit />
            <ScheduleEdit />
            <JumbotronEdit />
            <br></br>
            <ScheduleEditHarrow />
            <JumbotronEditHarrow />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
            </>
            ) : (
                <Login />
            )}
        </>
    )
}