import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena5Full from '../../components/Schedule/Whitby/IPSCArena5Full';


export default function IPSCArena5() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArena5Full />
        </>
    )
}