import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext'; // Import the context
import VenueSchedule from './VenueSchedule';
import './Schedule.css';

function ScheduleListHarrow() {
  const { schedulesHarrow, teams } = useContext(ScheduleContext); // Use context to get schedules and teams
  const [harrowSchedules, setHarrowSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];

    // Create a map of team codes to team names
    const teamMapping = teams.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for Harrow Arena
    const harrow = ensureFiveEntries(schedulesHarrow.filter(s => {
      if (s.Venue === 'Harrow Arena' && s.Date === today) {
        // Combine the schedule date and EndTime to create a complete Date object
        const [hours, minutes] = s.EndTime.split(':').map(Number);
        const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
        const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

        return now <= thirtyMinutesAfterEnd;
      }
      return false;
    }));

    setHarrowSchedules(harrow);
  }, [schedulesHarrow, teams]); // Rerender when schedules or teams context changes

  function ensureFiveEntries(schedules) {
    const now = new Date();
  
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Filter out past events (events that ended before now)
    const upcomingSchedules = schedules.filter(s => {
      const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
      return endTime >= now;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = upcomingSchedules.slice(0, 5);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 5) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueSchedule venueName="HARROW RINK" schedules={harrowSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleListHarrow;
