import React from 'react';

import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import MCKArena3Full from '../../components/Schedule/Whitby/MCKArena3Full';


export default function MCKArena3() {
    return (
        <>
            <HeaderIPSCLogo />
            <MCKArena3Full />
        </>
    )
}