import React, { createContext, useState, useEffect } from 'react';

export const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
    // Essex
    const [schedules, setSchedules] = useState([]);
    const [schedulesHarrow, setSchedulesHarrow] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [teams, setTeams] = useState([]);
    const [jumbotron, setJumbotron] = useState([]);
    const [jumbotronHarrow, setJumbotronHarrow] = useState([]);
    // Wellington
    const [schedulesWellington, setSchedulesWellington] = useState([]);
    const [selectedDateWellington, setSelectedDateWellington] = useState(new Date());
    const [teamsWellington, setTeamsWellington] = useState([]);
    const [adUrls, setAdUrls] = useState([]);
    // Guelph
    const [schedulesGuelph, setSchedulesGuelph] = useState([]);
    const [selectedDateGuelph, setSelectedDateGuelph] = useState(new Date());
    const [teamsGuelph, setTeamsGuelph] = useState([]);
    const [jumbotronGuelph, setJumbotronGuelph] = useState([]);
    // WestHants
    const [schedulesWestHants, setSchedulesWestHants] = useState([]);
    const [selectedDateWestHants, setSelectedDateWestHants] = useState(new Date());
    const [teamsWestHants, setTeamsWestHants] = useState([]);
    // Whitby
    const [schedulesWhitby, setSchedulesWhitby] = useState([]);
    const [selectedDateWhitby, setSelectedDateWhitby] = useState(new Date());
    const [teamsWhitby, setTeamsWhitby] = useState([]);
    const [jumbotronWhitby, setJumbotronWhitby] = useState([]);
    
    // URL
    const CLOUD_RUN_URL = 'https://arenascheduling.uc.r.appspot.com';

    // Essex
    useEffect(() => {
        fetchSchedules();
        fetchSchedulesHarrow();

        // const interval = setInterval(() => {
        //     fetchSchedules();
        //     fetchSchedulesHarrow();
        // }, 30000)

        // return () => clearInterval(interval);

    }, [selectedDate]);

    // Wellington
    useEffect(() => {
        fetchSchedulesWellington();

        // const interval = setInterval(() => {
        //     fetchSchedulesWellington();
        // }, 30000)

        // return () => clearInterval(interval);

    }, [selectedDateWellington]);

    // Guelph
    useEffect(() => {
        fetchSchedulesGuelph();

        // const interval = setInterval(() => {
        //     fetchSchedulesGuelph();
        // }, 30000)

        // return () => clearInterval(interval);

    }, [selectedDateGuelph])

    // WestHants
    useEffect(() => {
        fetchSchedulesWestHants();

        // const interval = setInterval(() => {
        //     fetchSchedulesGuelph();
        // }, 30000)

        // return () => clearInterval(interval);

    }, [selectedDateWestHants])

    // Whitby
    useEffect(() => {
        fetchSchedulesWhitby();
        // const interval = setInterval(() => {
        //     fetchSchedulesWellington();
        // }, 30000)

        // return () => clearInterval(interval);

    }, [selectedDateWhitby]);

    // Teams
    useEffect(() => {
        fetchTeams();
        fetchTeamsWellington();
        fetchTeamsGuelph();
        fetchTeamsWestHants();
        fetchTeamsWhitby();
    }, []);

    // Message Ticker
    useEffect(() => {
        fetchJumbotron();
        fetchJumbotronHarrow();
        fetchJumbotronGuelph();
    }, []);

    // Ads
    useEffect(() => {
        fetchAds();
    }, []);


    // Essex Schedules
    const fetchSchedules = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/essex/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        setSchedules(data);
    };

    const fetchSchedulesHarrow = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/harrow/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        const scheduleArray = Object.values(data);
        setSchedulesHarrow(scheduleArray);
    };

    const saveSchedules = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/essex/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedules(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleEssex = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/essex/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedules(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const saveSchedulesHarrow = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/harrow/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesHarrow(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleHarrow = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/harrow/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesHarrow(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEvent = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const addRecurringEventHarrow = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/harrow/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    // Essex Teams
    const fetchTeams = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const teamArray = Object.keys(data).map(key => ({
                Id: key,
                ...data[key]
            }));
            setTeams(teamArray);
        } catch (error) {
            console.error('Error fetching essex teams:', error);
        }
    };

    const addTeam = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeams(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeam = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeams(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeam = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeams(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    // Essex Message Ticker
    const fetchJumbotron = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const jumbotronArray = Object.values(data);  // Convert the response object to an array
            setJumbotron(jumbotronArray);
        } catch (error) {
            console.error('Error fetching jumbotron:', error);
        }
    };

    const fetchJumbotronHarrow = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const jumbotronArray = Object.values(data);  // Convert the response object to an array
            setJumbotronHarrow(jumbotronArray);
        } catch (error) {
            console.error('Error fetching jumbotron:', error);
        }
    };

    const addJumbotron = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const addJumbotronHarrow = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotron = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    };

    const updateJumbotronHarrow = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    };

    const deleteJumbotron = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };

    const deleteJumbotronHarrow = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };


    // Wellington Schedules
    const fetchSchedulesWellington = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/wellington/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        setSchedulesWellington(data);
    };

    const saveSchedulesWellington = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/wellington/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWellington(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWellington = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/wellington/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesWellington(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWellington = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/wellington/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    // Wellington Teams
    const fetchTeamsWellington = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const teamArray = Object.keys(data).map(key => ({
                Id: key,
                ...data[key]
            }));
            setTeamsWellington(teamArray);
        } catch (error) {
            console.error('Error fetching wellington teams:', error);
        }
    };

    const addTeamWellington = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWellington(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWellington = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWellington(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWellington = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWellington(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    // Ads - Wellington
    const fetchAds = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/`, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrls(data);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    const uploadAd = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrls(prevAdUrls => [...prevAdUrls, data.url]);
        } catch (error) {
            console.error('Error uploading ad:', error);
        }
    };

    const deleteAd = async (fileName) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAdUrls(prevAdUrls => prevAdUrls.filter(url => !url.includes(fileName)));
        } catch (error) {
            console.error('Error deleting ad:', error);
        }
    };


    // Guelph Schedules
    const fetchSchedulesGuelph = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/guelph/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        setSchedulesGuelph(data);
    };

    const saveSchedulesGuelph = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/guelph/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesGuelph(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleGuelph = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/guelph/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesGuelph(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventGuelph = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };
    
    // Guelph Teams
    const fetchTeamsGuelph = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const teamArray = Object.keys(data).map(key => ({
                Id: key,
                ...data[key]
            }));
            setTeamsGuelph(teamArray);
        } catch (error) {
            console.error('Error fetching guelph teams:', error);
        }
    };

    const addTeamGuelph = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsGuelph(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamGuelph = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsGuelph(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamGuelph = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsGuelph(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };
    
    // Guelph Message Ticker
    const fetchJumbotronGuelph = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const jumbotronArray = Object.values(data);  // Convert the response object to an array
            setJumbotronGuelph(jumbotronArray);
        } catch (error) {
            console.error('Error fetching jumbotron:', error);
        }
    };

    const addJumbotronGuelph = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotronGuelph = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronGuelph(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    }; 

    const deleteJumbotronGuelph = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronGuelph(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };


    // WestHants Schedules
    const fetchSchedulesWestHants = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/west-hants/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        setSchedulesWestHants(data);
    };

    const saveSchedulesWestHants = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/west-hants/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWestHants(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWestHants = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/west-hants/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedules(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWestHants = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/west-hants/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    // WestHants Teams
    const fetchTeamsWestHants = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const teamArray = Object.keys(data).map(key => ({
                Id: key,
                ...data[key]
            }));
            setTeamsWestHants(teamArray);
        } catch (error) {
            console.error('Error fetching west hants teams:', error);
        }
    };

    const addTeamWestHants = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWestHants(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWestHants = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWestHants(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWestHants = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWestHants(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };


    // Whitby Schedules
    const fetchSchedulesWhitby = async () => {
        const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/`, {
            headers: {'Content-Type': 'application/json'}
        });
        const data = await response.json();
        setSchedulesWhitby(data);
    };

    const saveSchedulesWhitby = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWhitby(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWhitby = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesWhitby(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWhitby = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };
    
    // Whitby Teams
    const fetchTeamsWhitby = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const teamArray = Object.keys(data).map(key => ({
                Id: key,
                ...data[key]
            }));
            setTeamsWhitby(teamArray);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const addTeamWhitby = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWhitby(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWhitby = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWhitby(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWhitby = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWhitby(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };
    
    // Whitby Message Ticker
    const fetchJumbotronWhitby = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/`, {
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const jumbotronArray = Object.values(data);  // Convert the response object to an array
            setJumbotronWhitby(jumbotronArray);
        } catch (error) {
            console.error('Error fetching jumbotron:', error);
        }
    };

    const addJumbotronWhitby = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronWhitby(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotronWhitby = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronGuelph(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    }; 

    const deleteJumbotronWhitby = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronWhitby(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };
    

    // Context Provider
    return (
        <ScheduleContext.Provider value={{
            schedules,
            selectedDate,
            setSelectedDate,
            saveSchedules,
            deleteScheduleEssex,
            setSchedules,
            fetchSchedules,
            addRecurringEvent,
            schedulesHarrow,
            fetchSchedulesHarrow,
            saveSchedulesHarrow,
            deleteScheduleHarrow,
            setSchedulesHarrow,
            addRecurringEventHarrow,
            teams,
            fetchTeams,
            addTeam,
            updateTeam,
            deleteTeam,
            jumbotron,
            fetchJumbotron,
            addJumbotron,
            updateJumbotron,
            deleteJumbotron,
            jumbotronHarrow,
            fetchJumbotronHarrow,
            addJumbotronHarrow,
            updateJumbotronHarrow,
            deleteJumbotronHarrow,

            schedulesWellington,
            selectedDateWellington,
            setSelectedDateWellington,
            fetchSchedulesWellington,
            saveSchedulesWellington,
            deleteScheduleWellington,
            setSchedulesWellington,
            teamsWellington,
            fetchTeamsWellington,
            addTeamWellington,
            updateTeamWellington,
            deleteTeamWellington,
            addRecurringEventWellington,
            adUrls,
            fetchAds,
            uploadAd,
            deleteAd,

            schedulesGuelph,
            selectedDateGuelph,
            setSelectedDateGuelph,
            saveSchedulesGuelph,
            setSchedulesGuelph,
            deleteScheduleGuelph,
            teamsGuelph,
            fetchTeamsGuelph,
            addTeamGuelph,
            updateTeamGuelph,
            deleteTeamGuelph,
            addRecurringEventGuelph,
            jumbotronGuelph,
            fetchJumbotronGuelph,
            addJumbotronGuelph,
            updateJumbotronGuelph,
            deleteJumbotronGuelph,

            schedulesWestHants,
            selectedDateWestHants,
            setSelectedDateWestHants,
            saveSchedulesWestHants,
            deleteScheduleWestHants,
            setSchedulesWestHants,
            addRecurringEventWestHants,
            teamsWestHants,
            fetchTeamsWestHants,
            addTeamWestHants,
            updateTeamWestHants,
            deleteTeamWestHants,

            schedulesWhitby,
            selectedDateWhitby,
            setSelectedDateWhitby,
            saveSchedulesWhitby,
            setSchedulesWhitby,
            deleteScheduleWhitby,
            teamsWhitby,
            fetchTeamsWhitby,
            addTeamWhitby,
            updateTeamWhitby,
            deleteTeamWhitby,
            addRecurringEventWhitby,
            jumbotronWhitby,
            fetchJumbotronWhitby,
            addJumbotronWhitby,
            updateJumbotronWhitby,
            deleteJumbotronWhitby,
        }}>
            {children}
        </ScheduleContext.Provider>
    );
};
