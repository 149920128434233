import React from 'react';
import HeaderWellington from '../../components/Header/Wellington/HeaderWellington';
import ScheduleListWellington from '../../components/Schedule/Wellington/ScheduleListWellington';
import RoomListWellington from '../../components/Room/Wellington/RoomListWellington';

export default function WellingtonList() {
    return (
        <>
            <HeaderWellington />
            <ScheduleListWellington />
            <RoomListWellington />
        </>
    )
}