import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DateSelector({ selectedDate, onDateChange }) {
  return (
    <div className="date-picker-container">
      <DatePicker selected={selectedDate} onChange={onDateChange} dateFormat="yyyy-MM-dd" showIcon inline/>
    </div>
  );
}
