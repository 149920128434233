import React, { useState, useEffect, useContext } from 'react';
import '../Header.css'; // Make sure to import your CSS file

export default function HeaderIPSCLogo() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark bg-gradient py-3">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img src="/logo_whitby.jpg" alt="Logo" width="270" height="90" className="d-inline-block align-text-top rounded img-thumbnail" />
                </div>
            </div>
        </nav>
    );
}
