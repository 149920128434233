import React, { useState } from 'react';
import Papa from 'papaparse';

const ImportCSV = ({ onImport }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    console.log("File selected:", event.target.files[0]); // Log file selection
    setFile(event.target.files[0]);
  };

  const handleImport = () => {
    if (file) {
      console.log("Starting CSV parsing"); // Log start of parsing
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          console.log("Parsing complete. Results:", results.data); // Log parsing results

          // Process the results to ensure they are in the correct format
          const processedData = results.data.map(item => {
            const date = new Date(item.Date);
            const formattedDate = isNaN(date) ? null : date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
            return {
              Date: formattedDate,
              StartTime: item.StartTime,
              EndTime: item.EndTime,
              Type: item.Type,
              Team: item.Team,
              HomeRoom: item.HomeRoom,
              Opponent: item.Opponent,
              AwayRoom: item.AwayRoom,
              Venue: item.Venue
            };
          });

          const validData = processedData.filter(item => item.Date); // Filter out invalid dates
          onImport(validData);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    } else {
      console.log("No file selected"); // Log if no file is selected
    }
  };

  return (
    <div className="d-flex align-items-center">
      <input type="file" accept=".csv" onChange={handleFileChange} className="form-control" />
      <button onClick={handleImport} className="btn btn-outline-success">Import</button>
    </div>
  );
};

export default ImportCSV;
