import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena4Full from '../../components/Schedule/Whitby/IPSCArena4Full';


export default function IPSCArena4() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArena4Full />
        </>
    )
}