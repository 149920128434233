import React from 'react';
import Header from '../components/Header/Header';
import ScheduleList from '../components/Schedule/ScheduleList';
import RoomList from '../components/Room/RoomList';
import Jumbotron from '../components/Jumbotron/Jumbotron'

export default function EssexSchedule() {
    return(
        <>
            <Header />
            <ScheduleList />
            <RoomList />
            <Jumbotron />
        </>
    );
}