import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import Ads from '../../Ads/Ads';
import RoomScheduleWellington from './RoomScheduleWellington';

export default function RoomListWellington() {
  const { schedulesWellington } = useContext(ScheduleContext);
  const [fullHallSchedules, setFullHallSchedules] = useState([]);
  const [grodBrownSchedules, setGrodBrownSchedules] = useState([]);
  const [unityHallSchedules, setUnityHallSchedules] = useState([]);
  const [boardroomASchedules, setBoardroomASchedules] = useState([]);
  const [boardroomBSchedules, setBoardroomBSchedules] = useState([]);
  const [mezzanineSchedules, setMezzanineSchedules] = useState([]);

  useEffect(() => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];

    const filterSchedules = (venueName) => {
      return schedulesWellington.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          // Combine the schedule date and EndTime to create a complete Date object
          const [hours, minutes] = s.EndTime.split(':').map(Number);
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    let fullHallSchedules = ensureNEntries(filterSchedules('Full Hall'));
    let grodBrown = ensureNEntries(filterSchedules('Grod Brown Hall'));
    let unityHall = ensureNEntries(filterSchedules('Unity Hall'));
    let boardroomA = ensureNEntries(filterSchedules('Boardroom A'));
    let boardroomB = ensureNEntries(filterSchedules('Boardroom B'));
    let mezzanine = ensureNEntries(filterSchedules('Mezzanine'));

    setFullHallSchedules(fullHallSchedules);
    setGrodBrownSchedules(grodBrown);
    setUnityHallSchedules(unityHall);
    setBoardroomASchedules(boardroomA);
    setBoardroomBSchedules(boardroomB);
    setMezzanineSchedules(mezzanine);

  }, [schedulesWellington]);

  function ensureNEntries(schedules) {
    const now = new Date();
  
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Filter out past events (events that ended before now)
    const upcomingSchedules = schedules.filter(s => {
      const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
      return endTime >= now;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = upcomingSchedules.slice(0, 2);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 2) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid text-center mt-2">
      <div className="row">
        <div className="col d-flex flex-column">
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Full Hall" schedules={fullHallSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Grod Brown Hall" schedules={grodBrownSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Unity Hall" schedules={unityHallSchedules} />
          </div>
        </div>
        <div className="col d-flex flex-column">
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Boardroom A" schedules={boardroomASchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Boardroom B" schedules={boardroomBSchedules} />
          </div>
          <div className="flex-fill">
            <RoomScheduleWellington venueName="Mezzanine" schedules={mezzanineSchedules} />
          </div>
        </div>
        <div className="col d-flex flex-column">
          <div className='flex-fill'>
            <Ads />
          </div>
        </div>
      </div>
    </div>
  );
}
