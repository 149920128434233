import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const CLOUD_RUN_URL = 'https://arenascheduling.uc.r.appspot.com';

  const loginEssex = async (username, password, navigate) => {
    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        navigate('/essex/edit');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const loginWellington = async (username, password, navigate) => {
    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        navigate('/wellington/edit');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const loginGuelph = async (username, password, navigate) => {
    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        navigate('/guelph/edit');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const loginWestHants = async (username, password, navigate) => {
    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        navigate('/west-hants/edit');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const loginWhitby = async (username, password, navigate) => {
    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        navigate('/whitby/edit');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const logout = (navigate) => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, loginEssex, loginWellington, loginGuelph, loginWestHants, loginWhitby, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
