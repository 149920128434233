import React, { useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext'; // Import the context
import VenueScheduleEditHarrow from './VenueScheduleEditHarrow';
import './Schedule.css';
import RoomEditHarrow from '../Room/RoomEditHarrow';

export default function ScheduleEditHarrow() {
  const { schedulesHarrow, selectedDate } = useContext(ScheduleContext);

  const filteredSchedules = (venueName) => schedulesHarrow.filter(s => s.Venue === venueName && s.Date === selectedDate);

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3">
        <h1 className='kanit-regular'>Harrow Arena Booking</h1>
        <VenueScheduleEditHarrow venueName="Harrow Arena" schedules={filteredSchedules('Harrow Arena')} />
        <h1 className='kanit-regular'>Harrow Room Booking</h1>
        <RoomEditHarrow venueName="Large Community Room" schedules={filteredSchedules('Large Community Room')} />
        <RoomEditHarrow venueName="Small Community Room" schedules={filteredSchedules('Small Community Room')} />
      </div>
    </div>
  );
}
