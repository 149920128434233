import React from 'react';

import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import IPSCArena1And4Full from '../../components/Schedule/Whitby/IPSCArena1And4Full';


export default function IPSCArena1And4() {
    return (
        <>
            <HeaderIPSCLogo />
            <IPSCArena1And4Full />
        </>
    )
}