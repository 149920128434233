import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScheduleProvider } from './context/ScheduleContext';
import { AuthProvider } from './context/AuthContext';

import EssexSchedule from './template/EssexSchedule';
import EssexEdit from './template/EssexEdit';
import WellingtonEdit from './template/wellington/WellingtonEdit'
import Login from './components/Auth/Login';
import EssexScheduleFull from './template/EssexScheduleFull';
import EssexScheduleAds from './template/EssexScheduleAds';
import HarrowSchedule from './template/HarrowSchedule';
import LandingPage from './template/LandingPage';
import WellingtonList from './template/wellington/WellingtonList';
import EloraList from './template/wellington/EloraList';
import GuelphEdit from './template/guelph/GuelphEdit';
import WestEndFull1Template from './template/guelph/WestEndFull1';
import WestEndFull2Template from './template/guelph/WestEndFull2';
import VictoriaFullTemplate from './template/guelph/VictoriaFull';
import CentennialFullTemplate from './template/guelph/CentennialFull';
import ExhibitionFullTemplate from './template/guelph/ExhibitionFull';
import WestHantsEdit from './template/westhants/WestHantsEdit';
import WestHantsArena from './template/westhants/WestHantsArena';
import WestHantsField from './template/westhants/WestHantsField';
import WhitbyEdit from './template/whitby/WhitbyEdit';
import IPSCArena2 from './template/whitby/IPSCArena2';
import IPSCArena3 from './template/whitby/IPSCArena3';
import IPSCArena4 from './template/whitby/IPSCArena4';
import IPSCArena5 from './template/whitby/IPSCArena5';
import IPSCArena6 from './template/whitby/IPSCArena6';
import IPSCArena1And4 from './template/whitby/IPSCArena1And4';
import IPSCArena2And5 from './template/whitby/IPSCArena2And5';
import IPSCArena3And6 from './template/whitby/IPSCArena3And6';
import MCKArena1 from './template/whitby/MCKArena1';
import MCKArena2 from './template/whitby/MCKArena2';
import MCKArena3 from './template/whitby/MCKArena3';
import LutherArena from './template/whitby/LutherArena';
import IPSCArenaRooms from './template/whitby/IPSCArenaRooms';

function App() {
  return (
    <ScheduleProvider>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path="/essex" element={<EssexSchedule />} />
            <Route path="/essex/edit" element={<EssexEdit />} />
            <Route path="/essex/login" element={<Login />} />
            <Route path="/essex-full" element={<EssexScheduleFull />}/>
            <Route path="/essex-ads" element={<EssexScheduleAds />} />
            <Route path="/harrow" element={<HarrowSchedule />} />

            <Route path='/wellington' element={<WellingtonList />} />
            <Route path='/elora' element={<EloraList />} />
            <Route path="/wellington/edit" element={<WellingtonEdit />} />

            <Route path='/guelph/edit' element={<GuelphEdit />}/>
            <Route path='/west-end-1' element={<WestEndFull1Template />} />
            <Route path='/west-end-2' element={<WestEndFull2Template />} />
            <Route path='/victoria' element={<VictoriaFullTemplate />} />
            <Route path='/centennial' element={<CentennialFullTemplate />} />
            <Route path='/exhibition' element={<ExhibitionFullTemplate />} />

            <Route path='/west-hants/edit' element={<WestHantsEdit />} />
            <Route path='/west-hants-arena' element={<WestHantsArena />} />
            <Route path='/west-hants-field' element={<WestHantsField />} />

            <Route path='/whitby/edit' element={<WhitbyEdit />} />
            <Route path='/ipsc-full' element={<IPSCArenaRooms />} />
            <Route path='/ipsc-2' element={<IPSCArena2 />} />
            <Route path='/ipsc-3' element={<IPSCArena3 />} />
            <Route path='/ipsc-4' element={<IPSCArena4 />} />
            <Route path='/ipsc-5' element={<IPSCArena5 />} />
            <Route path='/ipsc-6' element={<IPSCArena6 />} />
            <Route path='/ipsc-14' element={<IPSCArena1And4 />} />
            <Route path='/ipsc-25' element={<IPSCArena2And5 />} />
            <Route path='/ipsc-36' element={<IPSCArena3And6 />} />
            <Route path='/mck-1' element={<MCKArena1 />} />
            <Route path='/mck-2' element={<MCKArena2 />} />
            <Route path='/mck-3' element={<MCKArena3 />} />
            <Route path='/luther-vipond' element={<LutherArena />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ScheduleProvider>
  );
}

export default App;
