import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena6Full from '../../components/Schedule/Whitby/IPSCArena6Full';


export default function IPSCArena6() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArena6Full />
        </>
    )
}