import React, { useState } from 'react';
import { motion } from 'framer-motion';

function ContactUs() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [association, setAssociation] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailData = {
      firstName,
      lastName,
      phoneNumber,
      email,
      association,
      message,
    };

    const response = await fetch('https://arenascheduling.uc.r.appspot.com/api/send-email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    if (response.ok) {
      alert('Your message has been sent successfully!');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setEmail('');
      setAssociation('');
      setMessage('');
    } else {
      alert('There was an error sending your message. Please try again.');
    }
  };

  const slideInFromLeft = {
    initial: { opacity: 0, x: -100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration: 0.5 }
  };

  const slideInFromRight = {
    initial: { opacity: 0, x: 100 },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration: 0.5 }
  };

  return (
    <section className="py-5 mb-5" id="contact-us">
      <div className="container">
        <motion.h2 className="text-center mb-4" {...slideInFromLeft}>Contact Us</motion.h2>
        <p className="lead text-center mb-4">Request a demo or get in touch with us by filling out the form below.</p>
        <div className="row justify-content-center">
          <motion.div className="col-md-8" {...slideInFromRight}>
            <div className="card mb-4">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="first-name">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="first-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Enter your first name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="last-name">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="last-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter your last name"
                        required
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="phone-number">Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone-number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your phone number"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="association">Association</label>
                    <input
                      type="text"
                      className="form-control"
                      id="association"
                      value={association}
                      onChange={(e) => setAssociation(e.target.value)}
                      placeholder="Enter your association"
                      required
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="5"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Enter your message"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary btn-lg btn-block">Submit</button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
