import React from 'react';
import Header from '../components/Header/Header';
import ScheduleFull from '../components/Schedule/ScheduleFull';
import Jumbotron from '../components/Jumbotron/Jumbotron';

export default function EssexScheduleFull() {
    return(
        <>
            <Header />
            <ScheduleFull />
            <Jumbotron />
        </>
    );
}