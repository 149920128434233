import React from 'react';

import HeaderLuther from '../../components/Header/Whitby/HeaderLuther';
import LutherArenaFull from '../../components/Schedule/Whitby/LutherArenaFull';


export default function LutherArena() {
    return (
        <>
            <HeaderLuther />
            <LutherArenaFull />
        </>
    )
}