import React from 'react';

import MCKArena1Full from '../../components/Schedule/Whitby/MCKArena1Full';
import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';


export default function MCKArena1() {
    return (
        <>
            <HeaderIPSCLogo />
            <MCKArena1Full />
        </>
    )
}