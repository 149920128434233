import React from 'react';
import HeaderCentennial from '../../components/Header/Guelph/HeaderCentennial';
import CentennialFull from '../../components/Schedule/Guelph/CentennialFull';
import JumbotronCentennial from '../../components/Jumbotron/Guelph/JumbotronCentennial';

export default function CentennialFullTemplate () {
    return (
        <>
        <HeaderCentennial />
        <CentennialFull />
        <JumbotronCentennial />
        </>
    )
}