import React from 'react';

import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import IPSCArena3And6Full from '../../components/Schedule/Whitby/IPSCArena3And6Full';


export default function IPSCArena3And6() {
    return (
        <>
            <HeaderIPSCLogo />
            <IPSCArena3And6Full />
        </>
    )
}