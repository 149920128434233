import React, { useState, useEffect } from 'react';
import './Header.css'; // Make sure to import your CSS file

export default function HeaderHarrow() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const formattedDate = currentTime.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    const formattedTime = currentTime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-gradient py-3">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img src="/logo.png" alt="Logo" width="270" height="90" className="d-inline-block align-text-top rounded" />
                </div>
                <div className="text-center">
                    <span className="navbar-text display-4 text-white kanit-regular">
                        Harrow and Colchester South Community Centre
                    </span>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <span className="navbar-text h1 kanit-regular" style={{ color: '#FFFFFF' }}>
                        {formattedDate}
                    </span>
                    <span className="navbar-text h2 kanit-regular" style={{ color: '#FFFFFF' }}>
                        {formattedTime}
                    </span>
                </div>
            </div>
        </nav>
    );
}
