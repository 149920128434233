import React from 'react';
import '../Schedule.css';

function formatTime(time24) {
  if (!time24) return ''; // Return an empty string if time24 is undefined or null
  const [hour, minute] = time24.split(':');
  let hour12 = parseInt(hour, 10);
  const period = hour12 >= 12 ? 'PM' : 'AM';
  hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format, 0 becomes 12
  return `${hour12}:${minute}`;
}

function combineTimes(startTime, endTime) {
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return `${formattedStart}\n${formattedEnd}`;
}

export default function VenueSchedule({ venueName, schedules, teamMap }) {
    return (
      <div className="venue-schedule-container card" style={{ width: '550px' }}>
        <div className="card-header bg-primary bg-gradient text-white h1 kanit-regular">
          <strong>{venueName}</strong>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered text-center">
            <thead>
              <tr className="h3">
                <th className="time-column">Time</th>
                <th className="team-column">Team</th>
                <th className="room-column">Room</th>
              </tr>
            </thead>
            <tbody className="h3">
              {schedules.map((schedule, index) => (
                <tr key={index}>
                  <td className="time-column" style={{ whiteSpace: 'pre-wrap' }}>
                    {combineTimes(schedule.StartTime, schedule.EndTime)}
                  </td>
                  <td className="team-column" style={{ whiteSpace: 'pre-wrap' }}>
                    <div>{teamMap[schedule.Team] || schedule.Team || ""}</div>
                    <div>{teamMap[schedule.Opponent] || schedule.Opponent || ""}</div>
                  </td>
                  <td className="room-column">
                    <div>{schedule.HomeRoom || ""}</div>
                    <div>{schedule.AwayRoom || ""}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  