import React, { useState, useEffect, useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import RecurringEventModal from '../../Schedule/RecurringEventModal';
import '../Room.css'

export default function RoomEditWellington({ venueName }) {
  const { schedulesWellington, teams, saveSchedulesWellington, selectedDateWellington, addRecurringEventWellington } = useContext(ScheduleContext);
  const [editableSchedules, setEditableSchedules] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [teamList, setTeamList] = useState([]); // Initialize as an array
  const [showModal, setShowModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const dateStr = selectedDateWellington.toISOString().split('T')[0];
    
    const filteredSchedules = schedulesWellington.filter(s => s.Venue === venueName && s.Date === dateStr);
    setEditableSchedules(filteredSchedules);
  }, [schedulesWellington, venueName, selectedDateWellington]);

  useEffect(() => {
    if (teams) {
      const loadedTeams = Object.keys(teams).map(key => ({
        Id: key,
        Code: teams[key].Code,
        Name: teams[key].Name
      }));
      setTeamList(loadedTeams);
    }
  }, [teams]);

  const addNewRow = () => {
    setEditableSchedules(prev => [
      ...prev, 
      { Date: selectedDateWellington.toISOString().split('T')[0], StartTime: '', EndTime: '', Type: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Id: null }
    ]);
  };

  const handleScheduleChange = (index, field, value) => {
    const updatedSchedules = editableSchedules.map((schedule, idx) => {
      if (idx === index) {
        return { ...schedule, [field]: value, Venue: venueName };
      }
      return schedule;
    });
    setEditableSchedules(updatedSchedules);
  };

  const handleSaveSchedules = async () => {
    setSaving(true);
    setSaveError(null);
    try {
      const validSchedules = editableSchedules.filter(schedule => schedule.Date && schedule.StartTime && schedule.EndTime);

      if (validSchedules.length > 0) {
        await saveSchedulesWellington(venueName, validSchedules);
        alert("Schedules saved successfully. Please refresh to view changes");
      } else {
        console.error('No valid schedules to save.');
        setSaveError('No valid schedules to save.');
      }
    } catch (error) {
      console.error('Error saving schedules:', error);
      setSaveError(`Error: ${error.message}`);
    }
    setSaving(false);
  };

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleModalSubmit = async (formData) => {
    setSaveError(null);
    setSaving(true);
    try {
      console.log('Recurring Event Data:', formData);
      await addRecurringEventWellington(formData);
      handleModalClose();
    } catch (error) {
      console.error('Error adding recurring event:', error);
      setSaveError('Error adding recurring event:', error)
    }
    setSaving(false);
    alert("Recurring event added. Please refresh to view changes")
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='container-fluid'>
      <div className="col mb-4">
        <div className="card h-100">
          <div className="h2 btn btn-primary btn-lg card-header text-light" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
            {venueName}
          </div>
          <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
            <div className="card-body">
              <RecurringEventModal
                show={showModal}
                handleClose={handleModalClose}
                handleSubmit={handleModalSubmit}
                venueName={venueName}
              />
              {saving && <p>Saving schedules...</p>}
              {saveError && <p>{saveError}</p>}
              <div className="table-responsive">
                <table className="table table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editableSchedules.map((schedule, index) => (
                      <tr key={schedule.Id || index}>
                        <td className="col-12 col-md-3 mb-2">
                          <input type="date" className="form-control" value={schedule.Date || ''} onChange={(e) => handleScheduleChange(index, 'Date', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-3 mb-2">
                          <input type="time" className="form-control" value={schedule.StartTime || ''} onChange={(e) => handleScheduleChange(index, 'StartTime', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-3 mb-2">
                          <input type="time" className="form-control" value={schedule.EndTime || ''} onChange={(e) => handleScheduleChange(index, 'EndTime', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-3 mb-2">
                          <input type="text" className="form-control" value={schedule.Team || ''} onChange={(e) => handleScheduleChange(index, 'Team', e.target.value)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex flex-wrap">
                  <button className="btn btn-primary mb-2 me-2" onClick={handleSaveSchedules}>Save Changes</button>
                  <button className="btn btn-success mb-2 me-2" onClick={addNewRow}>Add New Event</button>
                  <button className="btn btn-success mb-2" onClick={handleModalShow}>Add Recurring Events</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
