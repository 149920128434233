import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena3Full from '../../components/Schedule/Whitby/IPSCArena3Full';


export default function IPSCArena3() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArena3Full />
        </>
    )
}