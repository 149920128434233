import React from 'react';

import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import IPSCArena2And5Full from '../../components/Schedule/Whitby/IPSCArena2And5Full';


export default function IPSCArena2And5() {
    return (
        <>
            <HeaderIPSCLogo />
            <IPSCArena2And5Full />
        </>
    )
}