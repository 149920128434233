import React from 'react';

import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena2Full from '../../components/Schedule/Whitby/IPSCArena2Full';


export default function IPSCArena2() {
    return (
        <>
            <HeaderIPSC />
            <IPSCArena2Full />
        </>
    )
}