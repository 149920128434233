import React, { useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is included

const Ads = () => {
    const { adUrls } = useContext(ScheduleContext);

    return (
        <div id="adsCarousel" className="carousel slide h-100" data-bs-ride="carousel" data-bs-interval="15000">
            {adUrls.length > 0 ? (
                <div className="carousel-inner h-100">
                    {adUrls.map((url, index) => (
                        <div key={index} className={`carousel-item h-100 ${index === 0 ? 'active' : ''}`}>
                            <img src={url} className="img-fluid h-100 w-100" alt={`Ad ${index + 1}`} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="ad-placeholder h-100" style={{ backgroundColor: 'transparent', height: '100%' }}></div>
            )}
        </div>
    );
};

export default Ads;
