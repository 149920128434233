import React, { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import HeaderWhitbyEdit from '../../components/Header/Whitby/HeaderWhitbyEdit';
import JumbotronEdit from '../../components/Jumbotron/Whitby/JumbotronEdit';
import ScheduleEdit from '../../components/Schedule/Whitby/ScheduleEdit';
import Teams from '../../components/Teams/Whitby/Teams';
import LoginWhitby from '../../components/Auth/Whitby/LoginWhitby';


export default function WhitbyEdit() {
const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
            <>
            <HeaderWhitbyEdit />
            <br></br>
            <ScheduleEdit />
            <br></br>
            <JumbotronEdit />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
            </>
            ) : (
                <LoginWhitby />
            )}
        </>
    )
}