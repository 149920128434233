import React, { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import Teams from '../../components/Teams/Guelph/Teams';
import LoginGuelph from '../../components/Auth/Guelph/LoginGuelph';
import HeaderGuelphEdit from '../../components/Header/Guelph/HeaderGuelphEdit';
import JumbotronEdit from '../../components/Jumbotron/Guelph/JumbotronEdit';
import ScheduleEdit from '../../components/Schedule/Guelph/ScheduleEdit';


export default function GuelphEdit() {
const { user } = useContext(AuthContext);

    return (
        <>
            {user ? (
            <>
            <HeaderGuelphEdit />
            <br></br>
            <ScheduleEdit />
            <br></br>
            <JumbotronEdit />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
            </>
            ) : (
                <LoginGuelph />
            )}
        </>
    )
}